import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BsChatLeftDotsFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { SlLock } from "react-icons/sl";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useReduxSelector } from "../redux/hooks";

type Inputs = {
	password?: string;
	confirmPassword?: string;
	patientNumber?: string;
};

const ForgotPassword = () => {
	const { isSignedIn } = useReduxSelector((state) => state.user);
	const navigate = useNavigate();
	React.useEffect(() => {
		isSignedIn && window.location.assign("/find-a-doctor");
	}, [isSignedIn, navigate]);
	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
	} = useForm<Inputs>();
	const [view, setView] = React.useState<string>("initial");
	const [value, setValue] = React.useState<number | null>(null);
	const [otp, setOtp] = React.useState<any>("");
	const [timer, setTimer] = React.useState<any>(null);
	const [showPassword, setShowPassword] = React.useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] =
		React.useState<boolean>(false);
	const [patientNumberInput, setPatientNumberInput] =
		React.useState<string>("");
	const [error, setError] = React.useState<boolean>(false);

	const id = React.useRef<any>(null);

	const startTimer = () => {
		setTimer(60);
	};

	const clear = () => {
		window.clearInterval(id.current);
	};
	React.useEffect(() => {
		id.current = window.setInterval(() => {
			setTimer((time: any) => time - 1);
		}, 1000);
		return () => clear();
	}, []);

	React.useEffect(() => {
		if (timer === 0) {
			clear();
		}
	}, [timer]);

	const handleChange = (e: any) => {
		e.preventDefault();
		setValue(e.target.value);
	};

	const handlePatientNumberChange = (e: any) => {
		e.preventDefault();
		setPatientNumberInput(e.target.value);
	};

	const handleOtpChange = (otp: any) => {
		setOtp(otp);
	};

	const handleOtp = () => {
		// console.log(otp);
	};

	const handlePasswordToggle = () => {
		setShowPassword(showPassword ? false : true);
	};

	const handleConfirmPasswordToggle = () => {
		setShowConfirmPassword(showConfirmPassword ? false : true);
	};

	const onSubmit: SubmitHandler<Inputs> = (data) => {
		// console.log( data )
	};

	return (
		<div className="py-8 md:py-0 px-5 md:px-14 h-full">
			<div className="flex w-full justify-between items-start">
				<div className="flex w-full flex-col justify-center items-center md:mt-6">
					<div className="flex w-full flex-col justify-center items-center">
						<img
							src="/assets/trust-hospital-logo.png"
							alt="hospital logo"
							className="w-40 cursor-pointer"
							onClick={() => navigate("/")}
						/>
					</div>
					{/* Enter patient number */}
					{view === "initial" ? (
						<div className="w-full md:w-10/12">
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6">
								<h1 className="text-2xl font-normal tracking-wide text-primary-100 text-center">
									Forgot Password
								</h1>
								<div className="my-6 flex justify-center">
									<img src="/assets/key.png" alt="Key" width={100} />
								</div>
								<p className="text-md text-gray-500 text-center w-full  text-sm my-3">
									To reset, please provide your patient number below
								</p>
								<form>
									<div>
										<input
											autoFocus
											required
											autoComplete="off"
											placeholder="Patient Number"
											type="text"
											id="password"
											onChange={handlePatientNumberChange}
											className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
										/>
										{error === true ? (
											<p className="my-2 text-sm text-red-600">*required</p>
										) : null}
									</div>
									<button
										className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
										onClick={() => {
											patientNumberInput.length > 0
												? setView("phonenumber")
												: setError(true);
										}}
									>
										Continue
									</button>
								</form>
							</div>
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
								<p className="text-xs text-center">
									Do you have an account?{" "}
									<span className="underline text-primary-50">
										<Link to="/signin">Sign in</Link>
									</span>
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/* Select Number to verify */}
					{view === "phonenumber" ? (
						<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6 md:w-10/12">
							<div className="flex flex-col items-center">
								<h1 className="text-2xl font-normal tracking-wide text-primary-100">
									One Time Passcode
								</h1>
								<div className="my-6">
									<img src="/assets/padlock.png" alt="Padlock" width={130} />
								</div>
								<p className="text-md text-[#202020] text-center w-full md:w-4/5">
									Select which contact details should we use to Verify your
									account.
								</p>
							</div>
							<div className="mt-8 w-full  flex flex-col">
								<button
									className="flex space-x-6 items-center border border-primary-50 rounded-xl px-3 py-2 focus:border-2 focus:outline-primary-50"
									value={+233792097456}
									onClick={handleChange}
								>
									<div className="bg-secondary-200 p-4 inline-flex rounded-full">
										<BsChatLeftDotsFill className="text-primary-50" />
									</div>
									<div>
										<p className="text-gray-500 text-sm md:text-base text-left">
											via SMS:
										</p>
										<p className="font-medium text-sm md:text-base">
											+233 792 097 ***
										</p>
									</div>
								</button>
								<button
									className="mt-5 flex space-x-6 items-center border border-primary-50 rounded-xl px-3 py-2 focus:border-2 focus:outline-primary-50"
									value={+233792097456}
									onClick={handleChange}
								>
									<div className="bg-secondary-200 p-4 inline-flex rounded-full">
										<BsChatLeftDotsFill className="text-primary-50" />
									</div>
									<div>
										<p className="text-gray-500 text-sm md:text-base text-left">
											via SMS:
										</p>
										<p className="font-medium text-sm md:text-base">
											+233 792 097 ***
										</p>
									</div>
								</button>

								<button
									className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
									onClick={() => {
										setView("otp");
										startTimer();
									}}
								>
									Continue
								</button>
							</div>
						</div>
					) : (
						""
					)}
					{/* Enter OTP */}
					{view === "otp" ? (
						<div className="w-full md:w-10/12">
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6  items-center">
								<div className="flex flex-col items-center">
									<h1 className="text-2xl font-normal tracking-wide text-primary-100">
										Verify Passcode
									</h1>
									<div className="my-6">
										<img
											src="/assets/checkmark.png"
											alt="Check mark"
											width={100}
										/>
									</div>
									<p className="text-sm text-[#202020] text-center w-full">
										Code has been sent to +233 792 097 ***
									</p>
								</div>
								<div className="w-full md:w-8/12 flex justify-center items-center my-6">
									<OtpInput
										value={otp}
										onChange={handleOtpChange}
										numInputs={4}
										isInputNum={true}
										shouldAutoFocus={true}
										onSubmit={handleOtp}
										inputStyle={{
											width: "3rem",
											height: "3rem",
											borderRadius: "6px",
											border: "1px solid rgba(0, 0, 0, 0.3)",
											outline: "#000",
											marginRight: "0.5rem",
										}}
										containerStyle={{
											width: "100%",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									/>
								</div>
								<p className="text-center text-xs">
									Resend code in{" "}
									<span className="text-primary-50">{timer} s</span>
								</p>
								<button
									className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
									onClick={() => setView("setpassword")}
								>
									Verify
								</button>
							</div>
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
								<p className="text-xs text-center">
									Don’t have an account?{" "}
									<span className="underline">
										<Link to="/signup">Sign up</Link>
									</span>
								</p>
							</div>
						</div>
					) : (
						""
					)}
					{/* Set Password */}
					{view === "setpassword" ? (
						<div className="w-full md:w-10/12">
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-10 mt-6">
								<h1 className="text-2xl font-normal tracking-wide text-primary-100 text-center">
									Set Password
								</h1>
								<div className="my-6 flex justify-center">
									<img src="/assets/key.png" alt="Key" width={100} />
								</div>
								<p className="text-md text-gray-500 text-left w-full md:w-4/5 text-sm my-3">
									Create Your New Password
								</p>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="relative">
										<SlLock className="absolute top-3 left-3" />
										<input
											autoFocus
											autoComplete="off"
											placeholder="Password"
											type={!showPassword ? "password" : "text"}
											id="password"
											aria-invalid={errors.password ? "true" : "false"}
											className="h-10 pl-10 pr-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
											{...register("password", {
												required: "Enter new password",
												minLength: {
													value: 8,
													message: "Password must have at least 8 characters",
												},
											})}
										/>
										<AiOutlineEyeInvisible
											className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showPassword ? "hidden" : "visible"}`}
											onClick={handlePasswordToggle}
										/>
										<AiOutlineEye
											className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showPassword ? "visible" : "hidden"}`}
											onClick={handlePasswordToggle}
										/>
										<p className="my-2 text-sm text-red-600">
											{errors.password && errors.password.message}
										</p>
									</div>
									<div className="relative mt-4">
										<SlLock className="absolute top-3 left-3" />
										<input
											autoComplete="off"
											placeholder="Confirm New Password"
											type={!showConfirmPassword ? "password" : "text"}
											id="confirmPassword"
											aria-invalid={errors.confirmPassword ? "true" : "false"}
											className="h-10 pl-10 pr-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 rounded-md bg-white"
											{...register("confirmPassword", {
												required: "Confirm your new password",
												validate: {
													matchesPreviousPassword: (value) => {
														const { password } = getValues();
														return (
															password === value || "Passwords should match!"
														);
													},
												},
											})}
										/>
										<AiOutlineEyeInvisible
											className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showConfirmPassword ? "hidden" : "visible"}`}
											onClick={handleConfirmPasswordToggle}
										/>
										<AiOutlineEye
											className={`absolute text-xl top-3
                      right-4 eye cursor-pointer
                      ${showConfirmPassword ? "visible" : "hidden"}`}
											onClick={handleConfirmPasswordToggle}
										/>
										<p className="my-2 text-sm text-red-600">
											{errors.confirmPassword && errors.confirmPassword.message}
										</p>
									</div>
									<button
										className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md hover:opacity-90"
										onClick={() => setView("setpassword")}
									>
										Continue
									</button>
								</form>
							</div>
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
								<p className="text-xs text-center">
									Do you have an account?{" "}
									<span className="underline text-primary-50">
										<Link to="/signin">Sign in</Link>
									</span>
								</p>
							</div>
						</div>
					) : (
						""
					)}
				</div>
				<div className="w-full flex-col hidden md:flex items-center">
					<img
						src={"/assets/doctor-slanted.svg"}
						alt="doctor"
						width={600}
						className="z-50"
					/>
					<img
						src={"/assets/blue_oval.svg"}
						alt="blue oval"
						width={550}
						className="absolute -bottom-20 right-0 z-10"
					/>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
