import React from "react";
import Layout from "../components/Layout";
import { FiImage } from "react-icons/fi";
import {
	useDropzone,
	DropzoneRootProps,
	DropzoneInputProps,
} from "react-dropzone";
import { BsCalendar4 } from "react-icons/bs";
import axios from "axios";
import { CgTrashEmpty } from "react-icons/cg";
import { HiOutlinePencil } from "react-icons/hi";
import { AnnouncementsData } from "../utils/dummydata";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

interface Timezone {
	zoneName: string;
	gmtOffset: number;
	countryName: string;
	regionName: string;
}

interface Region {
	name: string;
	timezones: Timezone[];
}

const Announcements = () => {
	const pathname = window.location.pathname;
	const [view, setView] = React.useState<"create" | "scheduled">("create");
	const [regions, setRegions] = React.useState<Region[]>([]);
	const [loaded, setLoaded] = React.useState<boolean>(false);
	const [file, setFile] = React.useState<{
		preview: string;
	}>({
		preview: "",
	});
	const [base64File, setBase64File] = React.useState<
		string | ArrayBuffer | null
	>();
	const [timeAnimation, setTimeAnimation] = React.useState({
		startTime: false,
		endTime: false,
		startDate: false,
		endDate: false,
	});

	React.useEffect(() => {
		const apiKey = "2EQCJWFUNIPW";
		const url = `http://api.timezonedb.com/v2.1/list-time-zone?key=${apiKey}&format=json&page=1`;

		axios
			.get<{ zones: Timezone[] }>(url)
			.then((response) => {
				const timezones = response.data.zones;
				const groupedTimezones = timezones.reduce(
					(acc: { [key: string]: Timezone[] }, timezone: Timezone) => {
						const regionName = timezone.zoneName.split("/")[0];
						if (!acc[regionName]) {
							acc[regionName] = [];
						}
						acc[regionName].push(timezone);
						return acc;
					},
					{}
				);

				const groupedRegions = Object.entries(groupedTimezones).map(
					([name, timezones]) => ({ name, timezones })
				);
				setRegions(groupedRegions);
				setLoaded(true);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	function handleDropdownOpen() {
		if (!loaded) {
			const apiKey = "YOUR_API_KEY";
			const url = `http://api.timezonedb.com/v2.1/list-time-zone?key=${apiKey}&format=json`;

			axios
				.get<{ zones: Timezone[] }>(url)
				.then((response) => {
					const timezones = response.data.zones;
					const groupedTimezones = timezones.reduce(
						(acc: { [key: string]: Timezone[] }, timezone: Timezone) => {
							const regionName = timezone.zoneName.split("/")[0];
							if (!acc[regionName]) {
								acc[regionName] = [];
							}
							acc[regionName].push(timezone);
							return acc;
						},
						{}
					);

					const groupedRegions = Object.entries(groupedTimezones).map(
						([name, timezones]) => ({ name, timezones })
					);
					setRegions(groupedRegions);
					setLoaded(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	}

	function getShortZoneName(zoneName: string): string {
		const parts = zoneName.split("/");
		return parts[parts.length - 1];
	}

	const {
		getRootProps,
		getInputProps,
		isDragActive,
	}: {
		getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
		getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
		isDragActive: boolean;
	} = useDropzone({
		accept: {
			"image/jpeg": [".jpeg"],
			"image/png": [".png"],
			"image/jpg": [".jpg"],
		},
		maxFiles: 1,
		maxSize: 1 * 1048576, //approximately 1mb
		onDrop: (acceptedFiles: File[]) => {
			const recentFile = acceptedFiles[0];
			const updatedFile = Object.assign(recentFile, {
				preview: URL.createObjectURL(recentFile),
			});
			setFile(updatedFile);
			const reader = new FileReader();
			reader.readAsDataURL(acceptedFiles[0]);
			reader.onloadend = () => {
				const base64: string | ArrayBuffer | null = reader.result;
				setBase64File(base64);
			};
		},
	});

	return (
		<Layout pathname={pathname} view={"Posts & Announcement"}>
			<div>
				<div className="flex justify-between items-center w-full">
					<div className="flex space-x-3 items-center">
						<button
							className={`rounded-md text-primary-100 border bg-white border-primary-100 ${
								view === "create" &&
								"text-white bg-primary-100 border border-primary-100"
							} py-2 px-3 lg:text-base text-xs`}
							onClick={() => setView("create")}
						>
							Create Announcement
						</button>
						<button
							className={`rounded-md text-primary-100 border bg-white border-primary-100 ${
								view === "scheduled" &&
								"text-white bg-primary-100 border border-primary-100"
							} py-2 px-3 lg:text-base text-xs`}
							onClick={() => setView("scheduled")}
						>
							Scheduled Posts
						</button>
					</div>
					<div className="hidden lg:block">
						<button className="bg-[#0E6DFF] rounded-md text-white border border-[#0E6DFF] py-2 px-3 text-sm">
							Create New Post
						</button>
					</div>
					<div className="block lg:hidden">
						<button className="bg-[#0E6DFF] rounded-md text-white border border-[#0E6DFF] py-1 px-3">
							+
						</button>
					</div>
				</div>

				<div className="mt-8 flex lg:flex-row flex-col justify-between items-start">
					{view === "create" && (
						<div className="w-full">
							<h1 className="text-primary-100 text-xl font-semibold tracking-wider">
								Announcement
							</h1>
							<div className="lg:fixed lg:h-[70%] xl:w-[40%] flex flex-col lg:overflow-y-scroll pb-10">
								<div
									className="bg-[#BBDAF7] md:w-[40%] flex flex-col items-center justify-center rounded-md shadow my-6 text-[#6B6A6A] py-8 px-3 hover:cursor-pointer"
									{...getRootProps()}
								>
									<input {...getInputProps()} />
									{Object.keys(file).length > 1 && (
										<img src={file?.preview} alt="preview" width={120} />
									)}
									{Object.keys(file).length === 1 && (
										<FiImage className="text-6xl" />
									)}
									{isDragActive ? (
										<p className="text-sm text-center mt-2">
											Drop the files here ...
										</p>
									) : !isDragActive && Object.keys(file).length === 1 ? (
										<p className="text-sm text-center mt-2">
											Drag & Drop or click to add profile image
										</p>
									) : (
										<p className="text-sm text-center mt-2">Click to change</p>
									)}
								</div>
								<form className="space-y-5 w-full">
									<div className="flex space-x-3 mt-3 items-center w-full">
										<fieldset className="relative border border-gray-400 rounded-md lg:w-[40%] text-gray-800 px-2">
											<legend
												className={`${
													!timeAnimation.startTime
														? "hidden"
														: "block ml-4 text-xs translate-y-0 transition-transform duration-500 ease-in-out"
												} translate-y-4`}
											>
												Start Time
											</legend>
											<BsCalendar4
												className={` absolute right-3 top-3.5 text-2xl text-gray-400 ${
													timeAnimation.startTime ? "hidden" : "block"
												}`}
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startTime: true,
													}))
												}
											/>
											<input
												type={timeAnimation.startTime ? "time" : "text"}
												name="startTime"
												id="startTime"
												placeholder={
													timeAnimation.startTime ? "" : "Start Time"
												}
												className="w-full p-3 focus:border-none focus:outline-none"
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startTime: true,
													}))
												}
											/>
										</fieldset>
										<fieldset className="relative border border-gray-400 rounded-md lg:w-[40%] text-gray-800 px-2">
											<legend
												className={`${
													!timeAnimation.startDate
														? "hidden"
														: "block ml-4 text-xs translate-y-0 transition-transform duration-500 ease-in-out"
												} translate-y-4`}
											>
												Start Date
											</legend>
											<BsCalendar4
												className={` absolute right-3 top-3.5 text-2xl text-gray-400 ${
													timeAnimation.startDate ? "hidden" : "block"
												}`}
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startTime: true,
													}))
												}
											/>
											<input
												type={timeAnimation.startDate ? "date" : "text"}
												name="startDate"
												id="startDate"
												placeholder={
													timeAnimation.startDate ? "" : "Start Date"
												}
												className="w-full p-3 focus:border-none focus:outline-none"
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startDate: true,
													}))
												}
											/>
										</fieldset>
									</div>
									<div className="flex space-x-4 items-center w-full">
										<fieldset className="relative border border-gray-400 rounded-md lg:w-[40%] text-gray-800 px-2">
											<legend
												className={`${
													!timeAnimation.endTime
														? "hidden"
														: "block ml-4 text-xs translate-y-0 transition-transform duration-500 ease-in-out"
												} translate-y-4`}
											>
												End Time
											</legend>
											<BsCalendar4
												className={` absolute right-3 top-3.5 text-2xl text-gray-400 ${
													timeAnimation.endTime ? "hidden" : "block"
												}`}
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startTime: true,
													}))
												}
											/>
											<input
												type={timeAnimation.endTime ? "time" : "text"}
												name="endTime"
												id="endTime"
												placeholder={timeAnimation.endTime ? "" : "End Time"}
												className="w-full p-3 focus:border-none focus:outline-none"
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														endTime: true,
													}))
												}
											/>
										</fieldset>
										<fieldset className="relative border border-gray-400 rounded-md lg:w-[40%] text-gray-800 px-2">
											<legend
												className={`${
													!timeAnimation.endDate
														? "hidden"
														: "block ml-4 text-xs translate-y-0 transition-transform duration-500 ease-in-out"
												} translate-y-4`}
											>
												End Date
											</legend>
											<BsCalendar4
												className={` absolute right-3 top-3.5 text-2xl text-gray-400 ${
													timeAnimation.endDate ? "hidden" : "block"
												}`}
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														startTime: true,
													}))
												}
											/>
											<input
												type={timeAnimation.endDate ? "date" : "text"}
												name="endDate"
												id="endDate"
												placeholder={timeAnimation.endDate ? "" : "End Date"}
												className="w-full p-3 focus:border-none focus:outline-none"
												onClick={() =>
													setTimeAnimation((prevState) => ({
														...prevState,
														endDate: true,
													}))
												}
											/>
										</fieldset>
									</div>
									<div className="w-full">
										<input
											type="text"
											placeholder="Title here"
											className="border border-gray-400 rounded-md p-3 lg:w-[90%] w-full"
										/>
									</div>
									<div className="w-full">
										<textarea
											name="description"
											id="description"
											placeholder="Write a short description."
											cols={30}
											rows={6}
											className="border border-gray-400 rounded-md w-full p-3"
										></textarea>
									</div>
								</form>
							</div>
						</div>
					)}
					{view === "scheduled" && (
						<div className="w-full">
							<div className="lg:fixed lg:h-[70%] lg:w-[50%] flex flex-col lg:overflow-y-scroll">
								<FullCalendar
									timeZone="UTC"
									initialView="dayGridMonth"
									events="https://fullcalendar.io/api/demo-feeds/events.json"
									plugins={[dayGridPlugin]}
									editable={true}
									selectable={true}
									selectMirror={true}
									dayMaxEvents={true}
									dayHeaderClassNames={"text-sm"}
									viewClassNames={"text-sm"}
									eventClassNames={"text-sm"}
									allDayClassNames={"text-sm"}
									dayCellClassNames={"text-sm"}
								/>
							</div>
						</div>
					)}
					<div className="lg:w-[30%] lg:relative w-full mt-8 lg:mt-0 justify-between mr-10">
						<div className="flex items-center space-x-6 justify-between lg:justify-start w-full">
							<h3 className="text-sm">Set Timezone</h3>
							<div className="lg:w-[30%]">
								<select
									className="text-xs rounded-md hover:cursor-pointer border"
									onClick={handleDropdownOpen}
								>
									{regions.map((region) => (
										<optgroup key={region.name} label={region.name}>
											{region.timezones.map((timezone) => (
												<option
													key={timezone.zoneName}
													value={timezone.zoneName}
													className="text-xs"
												>
													{getShortZoneName(timezone.zoneName)} (GMT{" "}
													{timezone.gmtOffset > 0 ? "+" : ""}
													{timezone.gmtOffset / 3600})
												</option>
											))}
										</optgroup>
									))}
								</select>
							</div>
						</div>
						<div className="lg:fixed h-[60%] lg:overflow-y-scroll mt-6 border border-gray-100 rounded-md px-4 py-6">
							<h3 className="font-semibold text-lg">Upcoming Announcements</h3>
							{AnnouncementsData.map((item) => (
								<div
									className="flex justify-between items-center mt-8"
									key={item.id}
								>
									<div className="flex items-center space-x-5">
										<img src={item.img} alt="assets" width={60} />
										<div>
											<p className="font-medium text-ellipsis overflow-hidden text-xs w-full ">
												{item.title}
											</p>
											<p className="text-gray-500 text-xs">Posting Date</p>
											<p className="text-[10px]">{item.date}</p>
										</div>
									</div>
									<div className="space-y-3">
										<div className="bg-[#FF6A6A] py-2 px-2 rounded text-white text-[10px] flex items-center justify-center">
											<CgTrashEmpty />
										</div>
										<div className="bg-primary-300 p-1.5 rounded text-white text-sm flex items-center justify-center">
											<HiOutlinePencil />
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Announcements;
