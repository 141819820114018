import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoading: false,
	isAuth: false,
	error: "",
	payload: {},
	isSignedIn: false,
};

const SigninSlice = createSlice({
	name: "signin",
	initialState,
	reducers: {
		signinPending: (state) => {
			state.isLoading = true;
		},
		signinSuccessful: (state, { payload }) => {
			state.isLoading = false;
			state.isAuth = true;
			state.error = "";
			state.payload = payload;
		},
		signinFailed: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		},
		resetSignin: () => initialState,
	},
});

const { reducer, actions } = SigninSlice;

export const { signinPending, signinSuccessful, signinFailed, resetSignin } = actions;

export default reducer;
