import React from 'react'

type Props = {
  label: string;
}

const Label = ({label}: Props) => {
  return (
		<label
			htmlFor={label}
			className="block text-sm text-tertiary-100 font-normal"
		>
			{label}
		</label>
	);
}

export default Label