import React from "react";
import Layout from "../components/Layout";
import { Avatar, Popover } from "@mui/material";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import { Completed, isCancelled } from "../utils/dummydata";
import { RxCalendar } from "react-icons/rx";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { AppointmentsAPI } from "../middleware";
import { Appointments } from "../utils/types";
import { convertUTCDatetoLocalDateWithDay, SignUserOut } from "../utils";

const VisitsAndAppointments = () => {
	const { isSignedIn, user } = useReduxSelector((state) => state.user);
	const { access_token, corporateHealthFacility } = user;
	const pathname = window.location.pathname;
	const navigate = useNavigate();
	const dispatch = useReduxDispatch()
	const [view, setView] = React.useState<string>("Completed");
	const [completedArray, setCompletedArray] = React.useState(Completed);
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [filteredAllAppnts, setFilteredAllAppnts] = React.useState<
		Appointments[]
	>([]);
	const [allAppointments, setAllAppointments] = React.useState<Appointments[]>(
		[]
	);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [isCancelledArray, setIsCancelledArray] = React.useState(isCancelled);
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// const handleSearch = (e: any) => {
	// 	e.preventDefault();
	// 	const query = e.target.value;
	// 	setSearchQuery(query);
	// 	if (view === "Completed") {
	// 		setCompletedArray(search(Completed, query));
	// 	}

	// 	if (view === "Appointments") {
	// 		setAppointmentsArray(search(Appointments, query));
	// 	}

	// 	if (view === "Cancelled") {
	// 		setIsCancelledArray(search(isCancelled, query));
	// 	}
	// };

	React.useEffect(() => {
		const patientId = user.user.patient.patientId;
		const healthFacilityId = corporateHealthFacility.id;
		const getAppointments = async () => {
			setIsLoading(true)
			try {
				const request = await AppointmentsAPI.getAppointments(
					access_token,
					healthFacilityId,
					patientId
				);
				const response = request.data.appointments;
				// console.log(response);
				setAllAppointments( response );
				setIsLoading(false)
			} catch (error: any) {
				setIsLoading(false);
				// console.log(error.response.data);
				if (
					error.response.data.errors.map(
						(e: any) =>
							e.message === "expired or invalid authorization token passed."
					)
				) {
					SignUserOut(dispatch, navigate, "/signin");
				}
			}
		};
		getAppointments();
	}, [access_token, corporateHealthFacility.id, dispatch, navigate, user.user.patient.patientId]);
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	function ascendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Completed") {
			setCompletedArray(arr);
		} else if (view === "Appointments") {
			setFilteredAllAppnts(arr);
		} else if (view === "Cancelled") {
			setIsCancelledArray(arr);
		}
		return arr;
	}

	function descendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa > fb) {
				return -1;
			}
			if (fa < fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Completed") {
			setCompletedArray(arr);
		} else if (view === "Appointments") {
			setFilteredAllAppnts(arr);
		} else if (view === "Cancelled") {
			setIsCancelledArray(arr);
		}
		return arr;
	}

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const lowerCaseValue = value.toLowerCase();
		setSearchTerm(lowerCaseValue);
		if (view === "Appointments") {
			const filtered = allAppointments.filter((appointment) => {
				return appointment.appointmentName
					.toLowerCase()
					.includes(lowerCaseValue);
			});
			setFilteredAllAppnts(filtered);
		}
	};

	const search = (
		appointments: Appointments[],
		query: string
	): Appointments[] => {
		setSearchTerm(query);
		const filtered = appointments.filter(
			(appointment: Appointments) =>
				appointment.appointmentName.toLowerCase().includes(query) ||
				appointment.appointmentId.toLowerCase().includes(query)
		);
		// console.log(filtered, "filtered");
		return filtered;
	};
	return (
		<>
			{isSignedIn === true && pathname === "/visits-and-appointments" ? (
				<Layout
					pathname={pathname}
					search={search}
					view={view}
					searchTerm={searchTerm}
					Completed={Completed}
					allAppointments={allAppointments}
					isCancelled={isCancelled}
					setCompletedArray={setCompletedArray}
					setFilteredAllAppnts={setFilteredAllAppnts}
					setIsCancelledArray={setIsCancelledArray}
				>
					<div>
						{/* Mobile search bar */}
						<div className="lg:hidden mb-3">
							<form className="w-full relative">
								<input
									placeholder={`Search ${
										view === "DoctorProfile" ? "Appointments" : view
									}`}
									className="border rounded-3xl w-full pl-4 pr-10 py-3 text-sm"
									type={"text"}
									value={searchTerm}
									onChange={handleSearch}
								/>
								<RiSearchLine className="absolute text-lg text-[#8E8E8E] top-[14px] right-5" />
							</form>
						</div>
						{/* Nav and sort area */}
						<div className="flex items-center justify-between">
							<div className="flex space-x-4 md:space-x-8 items-center text-sm">
								<div
									className="flex flex-col items-center cursor-pointer"
									onClick={() => setView("Completed")}
								>
									<h3
										className={`${
											view === "Completed" ? "text-black" : "text-[#888E99]"
										}`}
									>
										Completed
									</h3>
									<div
										className={`h-[5px] w-[5px] ${
											view === "Completed" ? "bg-primary-50" : "bg-white"
										} rounded-full inline`}
									></div>
								</div>
								<div
									className="flex flex-col items-center cursor-pointer"
									onClick={() => setView("Appointments")}
								>
									<h3
										className={`${
											view === "Appointments" ? "text-black" : "text-[#888E99]"
										}`}
									>
										Appointments
									</h3>
									<div
										className={`h-[5px] w-[5px] ${
											view === "Appointments" ? "bg-primary-50" : "bg-white"
										} rounded-full inline`}
									></div>
								</div>
								<div
									className="flex flex-col items-center cursor-pointer"
									onClick={() => setView("Cancelled")}
								>
									<h3
										className={`${
											view === "Cancelled" ? "text-black" : "text-[#888E99]"
										}`}
									>
										Cancelled
									</h3>
									<div
										className={`h-[5px] w-[5px] ${
											view === "Cancelled" ? "bg-primary-50" : "bg-white"
										} rounded-full inline`}
									></div>
								</div>
							</div>
							<div className="flex items-center space-x-3">
								<button
									className="flex space-x-3 items-center"
									onClick={handleClick}
								>
									<img src="/assets/filter.svg" alt="filter" width={30} />
									<p className="underline text-primary-100 text-sm hidden md:block">
										Sort by
									</p>
									<RiArrowDownSLine className="hidden md:block" />
								</button>
								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
								>
									<div className="flex flex-col rounded-md shadow">
										<button
											onClick={() => {
												if (view === "Completed") {
													ascendingOrder(Completed);
												} else if (view === "Appointments") {
													ascendingOrder(allAppointments);
												} else if (view === "Cancelled") {
													ascendingOrder(isCancelled);
												}
												handleClose();
											}}
											className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
										>
											A-Z
										</button>
										<button
											onClick={() => {
												if (view === "Completed") {
													descendingOrder(Completed);
												} else if (view === "Appointments") {
													descendingOrder(allAppointments);
												} else if (view === "Cancelled") {
													descendingOrder(isCancelled);
												}
												handleClose();
											}}
											className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
										>
											Z-A
										</button>
									</div>
								</Popover>
							</div>
						</div>
					</div>
					{view === "Completed" && (
						<div className="md:fixed h-[78%] mt-3 flex justify-center">
							<div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
								<div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
									{completedArray.length > 0 &&
										completedArray.map((item: any) => (
											<div
												className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
												key={item.id}
											>
												<img
													src={item.image}
													alt={item.name}
													className="w-[150px] md:w-[120px] rounded-full"
												/>
												<div className="flex flex-col items-start">
													<p className="text-primary-100 text-xl font-bold mt-2 tracking-wide">
														Dr. {item.name}
													</p>
													<p className="text-primary-100 text-xs font-medium mt-1">
														Patient Name - {item.patientName}
													</p>
													<p className="text-primary-100 text-xs font-normal mt-1">
														Visit Number - {item.visitNumber}
													</p>
													<div className="text-primary-100 flex items-center space-x-2 mt-1">
														<RxCalendar className="text-sm" />
														<p className="text-xs font-normal">
															{item.visitDate}
														</p>
													</div>
												</div>
												<p className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm">
													{item.amount} GHS
												</p>
											</div>
										))}
								</div>
								{completedArray.length === 0 && (
									<div className="flex w-full">
										<p className="text-red-500 text-base">
											Sorry! We can't find any completed appointments matching
											that search input. Please try something else.
										</p>
									</div>
								)}
							</div>
						</div>
					)}

					{view === "Appointments" && (
						<div className="md:fixed h-[78%] mt-3 flex justify-center">
							<div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
								<div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
									{searchTerm
										? filteredAllAppnts.map((item: any, key) => (
												<div
													className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full md:w-[220px]  xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
													key={key}
												>
													<Avatar
														src={item?.image}
														alt={"appointments"}
														// className="w-[150px] md:w-[120px] rounded-full"
														sx={{ width: 60, height: 60 }}
													/>
													<div className="flex flex-col items-start">
														<p className="text-primary-100 text-lg font-bold mt-3">
															{item.appointmentName}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Name - {item.patientName}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient ID - {item.patientId}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Age - {item.patientAge}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Gender - {item.gender}
														</p>
														<p className="text-primary-100 text-xs font-normal mt-1">
															Appointment ID - {item.appointmentId}
														</p>
														<div className="text-primary-100 flex items-center space-x-2 mt-1">
															<RxCalendar className="text-sm" />
															<p className="text-xs font-normal">
																{convertUTCDatetoLocalDateWithDay(
																	item.appointmentDate
																)}
															</p>
														</div>
													</div>
													<p className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm">
														{item.status}
													</p>
												</div>
										  ))
										: allAppointments.map((item: any, key) => (
												<div
													className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full md:w-[220px]  xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
													key={key}
												>
													<Avatar
														src={item?.image}
														alt={"appointments"}
														// className="w-[150px] md:w-[120px] rounded-full"
														sx={{ width: 60, height: 60 }}
													/>
													<div className="flex flex-col items-start">
														<p className="text-primary-100 text-lg font-bold mt-3">
															{item.appointmentName}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Name - {item.patientName}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient ID - {item.patientId}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Age - {item.patientAge}
														</p>
														<p className="text-primary-100 text-xs font-medium mt-1">
															Patient Gender - {item.gender}
														</p>
														<p className="text-primary-100 text-xs font-normal mt-1">
															Appointment ID - {item.appointmentId}
														</p>
														<div className="text-primary-100 flex items-center space-x-2 mt-1">
															<RxCalendar className="text-sm" />
															<p className="text-xs font-normal">
																{convertUTCDatetoLocalDateWithDay(
																	item.appointmentDate
																)}
															</p>
														</div>
													</div>
													<p className="bg-primary-100 text-white py-2 px-5 rounded-3xl mt-6 text-sm">
														{item.status}
													</p>
												</div>
										  ))}
								</div>
							</div>
						</div>
					)}

					{view === "Cancelled" && (
						<div className="md:fixed h-[78%] mt-3 flex justify-center">
							<div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
								<div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
									{isCancelledArray.length > 0 &&
										isCancelledArray.map((item: any) => (
											<div
												className="flex flex-col justify-center items-center bg-[#EC919F] rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95"
												key={item.id}
											>
												<img
													src={item.image}
													alt={item.name}
													className="w-[150px] md:w-[120px] rounded-full"
												/>
												<div className="flex flex-col items-start">
													<p className="text-primary-100 text-xl font-bold mt-2 tracking-wide">
														Dr. {item.name}
													</p>
													<p className="text-primary-100 text-xs font-medium mt-1">
														Patient Name - {item.patientName}
													</p>
													<p className="text-primary-100 text-xs font-normal mt-1">
														Visit Number - {item.visitNumber}
													</p>
													<div className="text-primary-100 flex items-center space-x-2 mt-1">
														<RxCalendar className="text-sm" />
														<p className="text-xs font-normal">
															{item.visitDate}
														</p>
													</div>
												</div>
												<p className="bg-[#F94C53] text-primary-100 font-medium py-2 px-5 rounded-3xl mt-6 text-sm">
													{item.status}
												</p>
											</div>
										))}
								</div>
								{isCancelledArray.length === 0 && (
									<div className="flex w-full">
										<p className="text-red-500 text-base">
											Sorry! We can't find any cancelled appointments matching
											that search input. Please try something else.
										</p>
									</div>
								)}
							</div>
						</div>
					)}
				</Layout>
			) : (
				<div className="flex flex-col justify-center items-center m-auto w-full h-screen">
					<p>Please sign in to continue</p>
					<button
						className="bg-primary-300 text-white py-3 px-8 mt-4 text-sm rounded-3xl shadow-2xl"
						onClick={() => navigate("/signin")}
					>
						Sign In
					</button>
				</div>
			)}
		</>
	);
};

export default VisitsAndAppointments;
