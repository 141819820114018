import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "../components/Input";
import Label from "../components/Label";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { UserAPI } from "../middleware";
import { Alert } from "@mui/material";
import { LoadingModal } from "../components/Modal";
import {
	SignupFailed,
	SignupPending,
	SignupSuccessful,
} from "../redux/slice/SignupSlice";

type Inputs = {
	username: string;
	patientNumber: string;
	dateOfBirth: string;
};

const SignUp = () => {
	const { isSignedIn } = useReduxSelector((state) => state.user);
	const { isLoading } = useReduxSelector((state) => state.signup);

	const [open, setOpen] = React.useState(false);
	const [error, setError] = React.useState<[] | null>(null);

	const navigate = useNavigate();
	const dispatch = useReduxDispatch();
	const handleClose = () => setOpen(false);

	React.useEffect(() => {
		isSignedIn && window.location.assign("/find-a-doctor");
	}, [isSignedIn, navigate]);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<Inputs>();

	const onSubmit: SubmitHandler<Inputs> = async (data) => {
		setOpen(true);
		dispatch(SignupPending());
		const { patientNumber, dateOfBirth, username } = data;
		let payload = {
			username,
			patientNumber,
			dateOfBirth,
			appClientToken: process.env.REACT_APP_CLIENT_CODE,
			signUpType: "existingPatient",
		};
		try {
			const tokenResponse = await UserAPI.gettoken();
			// console.log(tokenResponse);

			const token_type = tokenResponse.data.token_type;
			const access_token = tokenResponse.data.access_token;

			const signUpRequest = await UserAPI.signup(
				payload,
				token_type,
				access_token
			);
			// console.log(signUpRequest, "signUpRequest");
			dispatch(SignupSuccessful(signUpRequest));
			setOpen(false);
			navigate("/signin");
		} catch (error: any) {
			const errorMessage = error?.response.data.errors;
			dispatch(SignupFailed(errorMessage));
			setOpen(false);
			// console.log(error?.response.data.errors, "error");
			setError(errorMessage);
		}
	};
	return (
		<>
			{!isSignedIn ? (
				<div className="py-8 md:py-0 px-8 md:px-14 h-full">
					<div className="flex w-full justify-between items-center">
						<div className="flex w-full flex-col justify-center">
							<div className="flex w-full  flex-col justify-center items-center">
								<img
									src="/assets/trust-hospital-logo.png"
									alt="hospital logo"
									className="w-40 cursor-pointer"
									onClick={() => navigate("/")}
								/>
							</div>
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
								<h1 className="text-2xl font-medium text-center">Sign Up</h1>
								<p className="text-xs mt-2 text-gray-700 ">
									Already a patient of the hospital? Kindly sign up by filling
									the form below with your correct details and submit. Thanks!
								</p>
								{error &&
									error.map((item: any) => (
										<div key={item?.field}>
											<Alert severity="error" className="mt-2">
												{item?.message}
											</Alert>
										</div>
									))}
								<form
									onSubmit={handleSubmit(onSubmit)}
									className="mt-6 w-full  flex flex-col"
								>
									<div>
										<Label label="Username" />
										<div className="mt-3">
											<Input
												autoFocus
												type="text"
												id="username"
												placeholder="Your phone number is your username"
												{...register("username", { required: true })}
												aria-invalid={errors.username ? "true" : "false"}
											/>
										</div>
										{errors.username && (
											<p className="text-xs text-red-500 mt-1">
												*Your username is required
											</p>
										)}
									</div>
									<div className="mt-4">
										<Label label="Patient Number" />
										<div className="mt-3">
											<Input
												type="text"
												id="patientNumber"
												{...register("patientNumber", { required: true })}
												aria-invalid={errors.patientNumber ? "true" : "false"}
											/>
										</div>
										{errors.patientNumber && (
											<p className="text-xs text-red-500 mt-1">
												*Your patient number is required
											</p>
										)}
									</div>

									<div className="mt-4">
										<Label label="Date of Birth" />
										<div className="mt-3 w-full">
											<input
												type="date"
												id="dateOfBirth"
												max={new Date().toLocaleDateString("en-ca")}
												className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 w-full sm:text-sm border border-tertiary-200 rounded-md bg-white flex class-input"
												{...register("dateOfBirth", { required: true })}
												aria-invalid={errors.dateOfBirth ? "true" : "false"}
											/>
										</div>
										{errors.dateOfBirth && (
											<p className="text-xs text-red-500 mt-1">
												*Your date of birth is required
											</p>
										)}
									</div>

									<button className="w-full bg-primary-50 text-white mt-6 h-10 rounded-2xl font-medium text-md">
										Sign Up
									</button>
								</form>
								<p className="text-xs text-center mt-6">
									<span className="text-primary-50 underline">
										<Link to="/newsignup">Sign Up</Link>
									</span>{" "}
									as a new patient?
								</p>
							</div>
							<div className="px-6 md:px-10 border shadow-sm border-tertiary-200 rounded-xl flex flex-col justify-center py-6 mt-6">
								<p className="text-xs text-center">
									If you do not have your patient number, kindly call us on{" "}
									<a href="tel:+233302761975">
										<span className="text-primary-50 underline">
											+233 302 761 975{" "}
										</span>
									</a>{" "}
									before you proceed
								</p>
							</div>
							<div>
								<p className="text-xs text-center my-4">
									Already Have an Account?{" "}
									<span className="text-primary-50 underline">
										<Link to="/signin">Sign In</Link>
									</span>
								</p>
							</div>
						</div>
						<div className="w-full flex-col hidden md:flex items-center">
							<img
								src={"/assets/newuser-signup.svg"}
								alt="new user sign up"
								width={400}
							/>
						</div>
					</div>
					<LoadingModal
						open={open}
						handleClose={handleClose}
						isLoading={isLoading}
					/>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default SignUp;
