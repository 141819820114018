import React from "react";
import Layout from "../components/Layout";
import { Popover } from "@mui/material";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import { Lab_Investigations } from "../utils/dummydata";
import { RxCalendar } from "react-icons/rx";
import { BsImage } from "react-icons/bs";
import { MdArrowBack } from "react-icons/md";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { convertUTCDatetoLocalDateWithDay, SignUserOut } from "../utils";
import { InvestigationsAPI } from "../middleware";
import { Investigations } from "../utils/types";

const LabInvestigations = () => {
	const { isSignedIn, user } = useReduxSelector((state) => state.user);
	const { access_token, corporateHealthFacility } = user;
	const navigate = useNavigate();
	const dispatch = useReduxDispatch();
	const pathname = window.location.pathname;
	const [view, setView] = React.useState<string>("Lab Investigations");
	const [searchTerm, setSearchTerm] = React.useState<string>("");
	const [filteredInvestigations, setFilteredInvestigations] = React.useState<
		Investigations[]
	>([]);
	const [allInvestigations, setAllInvestigations] = React.useState<
		Investigations[]
	>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [labResults, setLabResults] = React.useState<any>();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const patientId = user.user.patient.patientId;
	const healthFacilityId = corporateHealthFacility.id;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	function ascendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Lab Investigations") {
			setAllInvestigations(arr);
		}
		return arr;
	}

	function descendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa > fb) {
				return -1;
			}
			if (fa < fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Lab Investigations") {
			setAllInvestigations(arr);
		}
		return arr;
	}

	React.useLayoutEffect(() => {
		const getAllInvestigations = async () => {
			setIsLoading(true);
			try {
				const request = await InvestigationsAPI.getInvestigations(
					access_token,
					healthFacilityId,
					patientId
				);
				const response = request.data.data as Investigations[];
				// console.log(response, "response");
				setAllInvestigations(response);
				setIsLoading(false);
			} catch (error: any) {
				setIsLoading(false);
				// console.log(error.response.data);
				if (
					error.response.data.errors.map(
						(e: any) =>
							e.message === "expired or invalid authorization token passed."
					)
				) {
					SignUserOut(dispatch, navigate, "/signin");
				}
			}
		};
		getAllInvestigations();
	}, [
		access_token,
		corporateHealthFacility.id,
		dispatch,
		healthFacilityId,
		navigate,
		patientId,
		user.user.patient.patientId,
	]);

	const search = (
		investigations: Investigations[],
		query: string
	): Investigations[] => {
		// console.log(investigations, query, "investigations");
		setSearchTerm(query);
		const filtered = investigations.filter(
			(investigation: Investigations) =>
				investigation.labTestName.toLowerCase().includes(query) ||
				investigation.specialistId.toLowerCase().includes(query) ||
				investigation.labTestId.toLowerCase().includes(query)
		);
		// console.log(filtered, "filtered");
		return filtered;
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		const lowerCaseValue = value.toLowerCase();
		setSearchTerm(lowerCaseValue);
		const filtered = allInvestigations.filter((investigations) => {
			return (
				investigations.labTestName.toLowerCase().includes(lowerCaseValue) ||
				investigations.specialistId.toLowerCase().includes(lowerCaseValue) ||
				investigations.labTestId.toLowerCase().includes(lowerCaseValue)
			);
		});
		setFilteredInvestigations(filtered);
	};

	const getInvestigationsByID = async (investigationId: any) => {
		setIsLoading(true);
		try {
			const request = await InvestigationsAPI.getByID(
				access_token,
				investigationId,
				healthFacilityId,
				patientId
			);
			const response = request.data.data as Investigations;
			// console.log(response, "response by Id");
			setLabResults(response);
			setIsLoading(false);
			setView("Lab Results");
		} catch (error: any) {
			setIsLoading(false);
			// console.log(error.response.data);
		}
	};

	const date = labResults?.requestDate as string;
	const requestDate = new Date(date);

	const currentDate = new Date();
	const monthDiff =
		(currentDate.getFullYear() - requestDate.getFullYear()) * 12 +
		(currentDate.getMonth() - requestDate.getMonth());
	// console.log(monthDiff);

	return (
		<>
			{isSignedIn === true && pathname === "/lab-investigations" ? (
				<Layout
					pathname={pathname}
					search={search}
					view={view}
					searchTerm={searchTerm}
					allInvestigations={allInvestigations}
					setFilteredInvestigations={setFilteredInvestigations}
				>
					<div>
						{/* Mobile search bar */}
						<div className="lg:hidden mb-3">
							<form className="w-full relative">
								<input
									placeholder={`Search ${
										view === "DoctorProfile" ? "Appointments" : view
									}`}
									className="border rounded-3xl w-full pl-4 pr-10 py-3 text-sm"
									type={"text"}
									value={searchTerm}
									onChange={handleSearch}
								/>
								<RiSearchLine className="absolute text-lg text-[#8E8E8E] top-[14px] right-5" />
							</form>
						</div>
						{/* Nav and sort area */}
						<div className="flex w-full justify-end">
							<div className="flex items-center space-x-3">
								<button
									className="flex space-x-3 items-center"
									onClick={handleClick}
								>
									<img src="/assets/filter.svg" alt="filter" width={30} />
									<p className="underline text-primary-100 text-sm hidden md:block">
										Sort by
									</p>
									<RiArrowDownSLine className="hidden md:block" />
								</button>
								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
								>
									<div className="flex flex-col rounded-md shadow">
										<button
											onClick={() => {
												if (view === "Lab Investigations") {
													ascendingOrder(Lab_Investigations);
												}
												handleClose();
											}}
											className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
										>
											A-Z
										</button>
										<button
											onClick={() => {
												if (view === "Lab Investigations") {
													descendingOrder(Lab_Investigations);
												}
												handleClose();
											}}
											className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
										>
											Z-A
										</button>
									</div>
								</Popover>
							</div>
						</div>
					</div>
					{view === "Lab Investigations" && (
						<div className="md:fixed h-[78%] mt-3 flex justify-center">
							<div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
								<div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
									{isLoading ? (
										<p>Loading...</p>
									) : (
										<>
											{searchTerm
												? filteredInvestigations.map((item: any, key) => (
														<div
															className="flex flex-col justify-center items-center bg-[#F2E4EC] rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95 text-[#98256B]"
															key={key}
														>
															<img
																src={"/assets/clipboard.svg"}
																alt={"lab investigations"}
																className="w-[150px] md:w-[120px] rounded-full"
															/>
															<div className="flex flex-col items-start">
																<p className="text-xl font-bold mt-2 tracking-wide">
																	{item.specialistId}
																</p>
																<p className="text-xs font-medium mt-1">
																	Test Name - {item.labTestName}
																</p>
																<p className="text-xs font-normal mt-1">
																	Lab Test ID - {item.labTestId}
																</p>
																<div className="flex items-center space-x-2 mt-1">
																	<RxCalendar className="text-sm" />
																	<p className="text-xs font-normal">
																		{convertUTCDatetoLocalDateWithDay(
																			item.requestDate
																		)}
																	</p>
																</div>
															</div>
															<button
																className="bg-[#98256B] text-white py-2 px-5 rounded-3xl mt-6 text-sm hover:opacity-80"
																onClick={() => {
																	getInvestigationsByID(item.labRequestId);
																}}
															>
																View Results
															</button>
														</div>
												  ))
												: allInvestigations.map((item: any, key) => (
														<div
															className="flex flex-col justify-center items-center bg-[#F2E4EC] rounded-3xl px-4 py-4 w-full h-[350px] md:w-[220px] md:h-[320px] xl:w-[268px] mt-4 xl:mt-0 hover:opacity-95 text-[#98256B]"
															key={key}
														>
															<img
																src={"/assets/clipboard.svg"}
																alt={"lab investigations"}
																className="w-[150px] md:w-[120px] rounded-full"
															/>
															<div className="flex flex-col items-start">
																<p className="text-xl font-bold mt-2 tracking-wide">
																	{item.specialistId}
																</p>
																<p className="text-xs font-medium mt-1">
																	Test Name - {item.labTestName}
																</p>
																<p className="text-xs font-normal mt-1">
																	Lab Test ID - {item.labTestId}
																</p>
																<div className="flex items-center space-x-2 mt-1">
																	<RxCalendar className="text-sm" />
																	<p className="text-xs font-normal">
																		{convertUTCDatetoLocalDateWithDay(
																			item.requestDate
																		)}
																	</p>
																</div>
															</div>
															<button
																className="bg-[#98256B] text-white py-2 px-5 rounded-3xl mt-6 text-sm hover:opacity-80"
																onClick={() => {
																	getInvestigationsByID(item.labRequestId);
																}}
															>
																View Results
															</button>
														</div>
												  ))}
										</>
									)}
								</div>
								{/* {allInvestigations.length === 0 && (
									<div className="flex w-full">
										<p className="text-red-500 text-base">
											Sorry! We can't find any Lab Investigations appointments
											matching that search input. Please try something else.
										</p>
									</div>
								)} */}
							</div>
						</div>
					)}
					{view === "Lab Results" &&
						labResults &&
						labResults.map((result: any) => (
							<div className="text-[#98256B] bg-[#E5C9DA80] rounded-3xl px-5 py-6 mt-3">
								<div className="flex space-x-4 items-center">
									<MdArrowBack
										className="text-2xl cursor-pointer"
										onClick={() => setView("Lab Investigations")}
									/>
									<h1 className="text-3xl font-bold">{result?.labTestName}</h1>
								</div>
								<p className="mt-6 font-medium">
									Doctor Name -{" "}
									<span className="underline">{result.specialistId}</span>
								</p>
								<p className="my-2">Visit Number - {result.labTestId}</p>
								<div className="flex items-center space-x-2">
									<RxCalendar className="" />
									<p className="font-normal">
										{convertUTCDatetoLocalDateWithDay(result.requestDate)}
									</p>
								</div>
								<div className="mt-10">
									<p className="text-[#696974] text-sm">
										Posted{" "}
										{monthDiff < 1
											? "recently"
											: monthDiff === 1
											? monthDiff + " month ago"
											: monthDiff + " months ago"}
									</p>
									<p>Nothing to see here</p>
								</div>
								<div className="mt-6 flex flex-wrap  justify-evenly md:space-x-4 md:justify-start items-center">
									<BsImage className="text-5xl" />
									<BsImage className="text-5xl" />
									<BsImage className="text-5xl" />
									<BsImage className="text-5xl" />
									<p className="text-xs underline">See more</p>
								</div>
							</div>
						))}
				</Layout>
			) : (
				<div className="flex flex-col justify-center items-center m-auto w-full h-screen">
					<p>Please sign in to continue</p>
					<button
						className="bg-primary-300 text-white py-3 px-8 mt-4 text-sm rounded-3xl shadow-2xl"
						onClick={() => navigate("/signin")}
					>
						Sign In
					</button>
				</div>
			)}
		</>
	);
};

export default LabInvestigations;
