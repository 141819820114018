import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	isLoading: false,
	isSuccess: false,
	isAuth: false,
	error: "",
	user: {
		new_signup: {},
		existing: {},
	},
};

const SignupSlice = createSlice({
	name: "signup",
	initialState,
	reducers: {
		SignupPending: (state) => {
			state.isLoading = true;
		},
		SignupSuccessful: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.error = "";
			state.user.existing = payload;
		},
		NewSignupSuccessful: (state, { payload }) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.isAuth = true;
			state.error = "";
			state.user.new_signup = payload;
		},
		SignupFailed: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload;
		},
		resetSignup: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isAuth = false;
			state.error = "";
			state.user = {
				new_signup: {},
				existing: {},
			};
		},
	},
});

const { reducer, actions } = SignupSlice;

export const {
	SignupPending,
	SignupSuccessful,
	SignupFailed,
	NewSignupSuccessful,
	resetSignup,
} = actions;

export default reducer;
