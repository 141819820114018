import React from "react";
import { RiArrowDownSLine, RiSearchLine } from "react-icons/ri";
import {
	AvailableTimes,
	Doctors,
	Locations,
	Specialty,
} from "../utils/dummydata";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Chip, Popover } from "@mui/material";
import { TiLocation } from "react-icons/ti";
import Layout from "../components/Layout";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { setSelectedSpecialty } from "../redux/slice/UserSlice";

type DoctorInfoType = {
	id: number;
	name: string;
	profession: string;
	availability: string;
	image: string;
	alt: string;
	about: string;
};

const FindADoctor = () => {
	const { isSignedIn, selectedSpecialty } = useReduxSelector(
		(state) => state.user
	);
	const dispatch = useReduxDispatch();
	const [view, setView] = React.useState<string>("Specialty");
	const [searchQuery, setSearchQuery] = React.useState("");
	const [doctorInfo, setDoctorInfo] = React.useState<DoctorInfoType>();
	const [value, onChange] = React.useState(new Date());
	const [specialtyArray, setSpecialtyArray] = React.useState(Specialty);
	const [doctorsArray, setDoctorsArray] = React.useState(Doctors);
	const [locationsArray, setLocationsArray] = React.useState(Locations);
	const [addressLocation, setAddressLocation] = React.useState("Accra, Ghana");
	const [selectedDoctor, setSelectedDoctor] = React.useState({
		id: 0,
		name: "",
	});
	const [selectedLocation, setSelectedLocation] = React.useState({
		id: 0,
		name: "",
	});
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleSearch = (e: any) => {
		e.preventDefault();
		const query = e.target.value;
		setSearchQuery(query);
		if (view === "Specialty") {
			setSpecialtyArray(search(Specialty, query));
		}

		if (view === "Doctors") {
			setDoctorsArray(search(Doctors, query));
		}

		if (view === "Location") {
			setLocationsArray(search(Locations, query));
		}
	};
	const pathname = window.location.pathname;
	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	function ascendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa < fb) {
				return -1;
			}
			if (fa > fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Specialty") {
			setSpecialtyArray(arr);
		} else if (view === "Doctors") {
			setDoctorsArray(arr);
		} else if (view === "Location") {
			setLocationsArray(arr);
		}
		return arr;
	}

	function descendingOrder(arr: any) {
		arr.sort((a: any, b: any) => {
			let fa = a.name.toLowerCase(),
				fb = b.name.toLowerCase();

			if (fa > fb) {
				return -1;
			}
			if (fa < fb) {
				return 1;
			}
			return 0;
		});
		if (view === "Specialty") {
			setSpecialtyArray(arr);
		} else if (view === "Doctors") {
			setDoctorsArray(arr);
		} else if (view === "Location") {
			setLocationsArray(arr);
		}
		return arr;
	}

	function search(array: any, query: string) {
		return array.filter(function (element: any) {
			return element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
		});
	}

	return (
		<Layout
			pathname={pathname}
			search={search}
			view={view}
			Specialty={Specialty}
			Doctors={Doctors}
			Locations={Locations}
			setSpecialtyArray={setSpecialtyArray}
			setDoctorsArray={setDoctorsArray}
			setLocationsArray={setLocationsArray}
		>
			<div>
				{/* Mobile search bar */}
				<div className="lg:hidden mb-3">
					<form className="w-full relative">
						<input
							placeholder={`Search ${
								view === "DoctorProfile" ? "Doctors" : view
							}`}
							className="border rounded-3xl w-full pl-4 pr-10 py-3 text-sm"
							type={"text"}
							value={searchQuery}
							onChange={handleSearch}
						/>
						<RiSearchLine className="absolute text-lg text-[#8E8E8E] top-[14px] right-5" />
					</form>
				</div>
				{/* Nav and sort area */}
				<div className="flex items-center justify-between">
					<div className="flex space-x-4 md:space-x-8 items-center text-sm">
						<div
							className="flex flex-col items-center cursor-pointer"
							onClick={() => setView("Specialty")}
						>
							<h3
								className={`${
									view === "Specialty" ? "text-black" : "text-[#888E99]"
								}`}
							>
								Specialty
							</h3>
							<div
								className={`h-[5px] w-[5px] ${
									view === "Specialty" ? "bg-primary-50" : "bg-white"
								} rounded-full inline`}
							></div>
						</div>
						{isSignedIn && (
							<div
								className="flex flex-col items-center cursor-pointer"
								onClick={() => setView("Doctors")}
							>
								<h3
									className={`${
										view === "Doctors" || view === "DoctorProfile"
											? "text-black"
											: "text-[#888E99]"
									}`}
								>
									Doctors
								</h3>
								<div
									className={`h-[5px] w-[5px] ${
										view === "Doctors" || view === "DoctorProfile"
											? "bg-primary-50"
											: "bg-white"
									} rounded-full inline`}
								></div>
							</div>
						)}
						{isSignedIn && (
							<div
								className="flex flex-col items-center cursor-pointer"
								onClick={() => setView("Location")}
							>
								<h3
									className={`${
										view === "Location" ? "text-black" : "text-[#888E99]"
									}`}
								>
									Location
								</h3>
								<div
									className={`h-[5px] w-[5px] ${
										view === "Location" ? "bg-primary-50" : "bg-white"
									} rounded-full inline`}
								></div>
							</div>
						)}
					</div>
					<div className="flex items-center space-x-3">
						<button
							className="flex space-x-3 items-center"
							onClick={handleClick}
						>
							<img src="/assets/filter.svg" alt="filter" width={30} />
							<p className="underline text-primary-100 text-sm hidden md:block">
								Sort by
							</p>
							<RiArrowDownSLine className="hidden md:block" />
						</button>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleClose}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
						>
							<div className="flex flex-col rounded-md shadow">
								<button
									onClick={() => {
										if (view === "Specialty") {
											ascendingOrder(Specialty);
										} else if (view === "Doctors") {
											ascendingOrder(Doctors);
										} else if (view === "Location") {
											ascendingOrder(Locations);
										}
										handleClose();
									}}
									className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
								>
									A-Z
								</button>
								<button
									onClick={() => {
										if (view === "Specialty") {
											descendingOrder(Specialty);
										} else if (view === "Doctors") {
											descendingOrder(Doctors);
										} else if (view === "Location") {
											descendingOrder(Locations);
										}
										handleClose();
									}}
									className="border px-4 py-2 hover:bg-gray-100 focus:bg-primary-10"
								>
									Z-A
								</button>
							</div>
						</Popover>
					</div>
				</div>
				{/* selected items bar */}
				<div className="mt-3 space-x-3 flex flex-wrap">
					{selectedSpecialty.id > 0 && (
						<Chip
							label={selectedSpecialty.name}
							variant="outlined"
							color="primary"
							className="mr-2 font-montserrat"
							onDelete={() => {
								dispatch(setSelectedSpecialty({ id: 0, name: "" }));
							}}
						/>
					)}
					{selectedDoctor.id > 0 && (
						<Chip
							label={selectedDoctor.name}
							variant="outlined"
							color="primary"
							className="mr-2 font-montserrat"
							onDelete={() => {
								setSelectedDoctor({ id: 0, name: "" });
							}}
						/>
					)}
					{selectedLocation.id > 0 && (
						<Chip
							label={selectedLocation.name}
							variant="outlined"
							color="primary"
							className="mr-2 font-montserrat"
							onDelete={() => {
								setSelectedLocation({ id: 0, name: "" });
							}}
						/>
					)}
				</div>
			</div>
			{view === "Specialty" && (
				<div>
					<div>
						<h1 className="text-lg mt-8 text-primary-100">
							Select a Specialty
						</h1>
					</div>
					<div className="md:fixed h-[70%] mt-3 flex justify-center">
						<div className="justify-start flex flex-col flex-wrap md:grid items-start grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full relative max-h-[95%] mb-3 md:mb-0 overflow-y-scroll pb-2">
							{specialtyArray.length > 0 &&
								specialtyArray.map((item: any) => (
									<div
										className={`flex flex-col justify-center items-center ${
											item.id === selectedSpecialty.id
												? "bg-primary-50"
												: "bg-primary-500"
										} rounded-3xl px-8 py-4 w-full h-[250px] md:w-[220px] md:h-[220px] xl:w-[268px] mt-4 xl:mt-0 cursor-pointer`}
										key={item.id}
										onClick={() => {
											dispatch(
												setSelectedSpecialty({ id: item.id, name: item.name })
											);
											if (isSignedIn === true) {
												setView("Doctors");
											}
										}}
									>
										<img
											src={item.image}
											alt={item.alt}
											className="w-[100px]"
										/>
										<p className="text-primary-100 text-lg mt-2 tracking-wide">
											{item.name}
										</p>
									</div>
								))}
						</div>
					</div>
					{specialtyArray.length === 0 && (
						<div className="flex w-full md:mt-8">
							<p className="md:mt-2 text-red-500 text-base">
								Sorry! We can't find any specialties matching that search input.
								Please try something else.
							</p>
						</div>
					)}
				</div>
			)}

			{view === "Doctors" && (
				<div className="md:fixed h-[78%] mt-3 flex justify-center">
					<div className="overflow-y-scroll relative max-h-[95%] flex flex-col w-full">
						<div className="flex flex-col flex-wrap md:grid items-center justify-center grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full mb-3 md:mb-0">
							{doctorsArray.length > 0 &&
								doctorsArray.map((item: any) => (
									<div
										className="flex flex-col justify-center items-center bg-primary-500 rounded-3xl px-4 py-4 w-full h-[250px] md:w-[220px] md:h-[220px] xl:w-[268px] mt-4 xl:mt-0 cursor-pointer hover:bg-primary-50 doctor-div"
										key={item.id}
										onClick={() => {
											setDoctorInfo(item);
											setView("DoctorProfile");
											setSelectedDoctor({
												id: item.id,
												name: item.name,
											});
										}}
									>
										<img
											src={item.image}
											alt={item.alt}
											className="w-[100px] rounded-full"
										/>
										<p className="text-primary-100 text-base font-semibold mt-2 tracking-wide">
											Dr. {item.name}
										</p>
										<p className="text-secondary-400 text-xs font-medium mt-1 profession">
											{item.profession}
										</p>
										<p className="text-primary-100 text-sm font-normal mt-1">
											Available: {item.availability}
										</p>
									</div>
								))}
						</div>
						{doctorsArray.length === 0 && (
							<div className="flex w-full md:mt-8">
								<p className="md:mt-2 text-red-500 text-base">
									Sorry! We can't find any doctors matching that search input.
									Please try something else.
								</p>
							</div>
						)}
					</div>
				</div>
			)}

			{view === "DoctorProfile" && (
				<div className="mt-4">
					<div className="overflow-y-scroll relative max-h-[95%] w-full flex flex-col lg:flex-row justify-between">
						<div className="text-primary-100 lg:w-[45%]">
							<div className="mt-2 rounded-full bg-white shadow-md inline-flex p-[2px] ml-1">
								<img
									src={doctorInfo?.image}
									alt={doctorInfo?.alt}
									className="rounded-full"
								/>
							</div>
							<div className="mt-3">
								<div className="flex items-center space-x-6">
									<h3 className="font-medium text-lg">{doctorInfo?.name}</h3>
									<button
										className="rounded-3xl bg-primary-100 text-white text-xs py-2 px-4"
										onClick={() => setView("Doctors")}
									>
										Change Doctor
									</button>
								</div>
								<p className="text-tertiary-400">{doctorInfo?.profession}</p>
								<h3 className="text-base mt-4 mb-1 font-medium">
									About Doctor
								</h3>
								<p className="text-sm text-[#6B779A] text-justify tracking-wide">
									{doctorInfo?.about}
								</p>

								<div className="flex items-center mt-5 space-x-4">
									<img
										src="/assets/location.svg"
										alt="location icon"
										width={28}
									/>
									<div>
										<h3 className="text-sm font-medium">Trust Hospital Osu</h3>
										<p className="text-sm text-gray-400">
											Oxford St, Accra, Ghana
										</p>
									</div>
									<button
										className="rounded-3xl bg-primary-100 text-white text-xs py-2 px-4"
										onClick={() => setView("Location")}
									>
										Change Location
									</button>
								</div>
							</div>
						</div>
						<div className="lg:w-[45%] w-full mt-8 lg:mt-0">
							<div className="mt-3 flex flex-col items-center h-[80%] w-full">
								<div className="border border-primary-100 lg:bg-primary-100 p-2 w-full rounded-2xl mb-4">
									<h3 className="text-primary-100 lg:text-white text-center">
										Doctor's Availability
									</h3>
								</div>
								<div className="scroll-smooth overflow-y-scroll relative max-h-[95%] w-full lg:px-8 pb-4 lg:pb-0">
									<Calendar
										onChange={onChange}
										value={value}
										className="rounded-2xl w-4/5 p-1 shadow-md"
									/>
									<div className="mt-4 grid grid-cols-3 gap-2">
										{AvailableTimes.map((time) => (
											<button
												key={time.id}
												className="border-primary-100 border rounded-3xl hover:bg-primary-100 hover:text-white focus:bg-primary-100 focus:text-white shadow py-1.5 text-sm"
											>
												{time.time}
											</button>
										))}
									</div>
									<div className="flex justify-end mt-3">
										<button className="bg-primary-100 hover:opacity-70 text-white py-2 px-8 rounded-3xl">
											Book Appointment
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			{view === "Location" && (
				<div className="flex flex-col md:flex-row justify-between mt-8">
					<div className="w-full relative">
						{/* mobile view map */}
						<div className="bg-gray-50 w-full h-[300px] rounded-2xl lg:hidden block z-50">
							<iframe
								title="map"
								className="w-full h-[300px] border-none rounded-3xl"
								aria-hidden="false"
								src={`https://maps.google.com/maps?q=${encodeURI(
									addressLocation
								)}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
								loading="lazy"
								style={{ filter: "grayscale(0) contrast(0.6) opacity(1)" }}
							></iframe>
						</div>
						<div className="lg:w-10/12 h-[400px] overflow-y-scroll lg:px-2">
							<h1 className="text-lg text-primary-100 hidden lg:block mb-2">
								Locations
							</h1>
							<div className="space-y-3 lg:pb-4 absolute top-48 lg:top-0 lg:relative lg:z-50 z-10 lg:bg-none bg-white rounded-3xl w-full shadow lg:shadow-none">
								{locationsArray.map((item) => (
									<div
										key={item.id}
										className="flex items-center space-x-4 mt-4 hover:bg-primary-10 focus:bg-primary-10 bg-white  shadow-primary-100 shadow rounded-2xl py-3 px-4 cursor-pointer"
										onClick={(e: any) => {
											e.preventDefault();
											setAddressLocation(item.name);
											setSelectedLocation({
												id: item.id,
												name: item.name,
											});
										}}
									>
										<div className="flex space-x-4 items-center">
											<div className="bg-[#E5E5FE] rounded-full h-10 w-10 flex items-center justify-center">
												<TiLocation className="text-2xl text-primary-100" />
											</div>
											<div>
												<h3 className="text-primary-100">{item.name}</h3>
												<p className="text-tertiary-400 text-xs">
													{item.address}
												</p>
											</div>
										</div>
									</div>
								))}

								{locationsArray.length === 0 && (
									<div className="flex w-full px-3 items-center justify-center">
										<p className=" text-red-500 text-base mt-4">
											Sorry! We can't find any locations matching that search
											input. Please try something else.
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
					{/* Desktop view map */}
					<div className="bg-gray-50 w-full h-[400px] rounded-2xl hidden lg:block">
						<iframe
							title="map"
							className=" border-none rounded-3xl w-full h-full"
							aria-hidden="false"
							src={`https://maps.google.com/maps?q=${encodeURI(
								addressLocation
							)}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
							loading="lazy"
							// style={{ filter: "grayscale(1) contrast(1.2) opacity(0.16)" }}
						></iframe>
					</div>
				</div>
			)}
		</Layout>
	);
};

export default FindADoctor;
