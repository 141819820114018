import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import NewSignUp from "./pages/newsignup";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import ForgotPassword from "./pages/forgot_password";
import Payments from "./pages/payment";
import FindADoctor from "./pages/find-a-doctor";
import VisitsAndAppointments from "./pages/visits_and_appointments";
import LabInvestigations from "./pages/lab_investigations";
import Prescriptions from "./pages/prescriptions";
import Announcements from "./pages/announcements";

function App() {
	return (
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/newsignup" element={<NewSignUp />} />
					<Route path="/signup" element={<SignUp />} />
					<Route path="/signin" element={<SignIn />} />
					<Route path="/forgot_password" element={<ForgotPassword />} />
					<Route path="/payments" element={<Payments />} />
					<Route path="/find-a-doctor" element={<FindADoctor />} />
					<Route
						path="/visits-and-appointments"
						element={<VisitsAndAppointments />}
					/>
					<Route path="/lab-investigations" element={<LabInvestigations />} />
					<Route path="/prescriptions" element={<Prescriptions />} />
					<Route path="/announcements" element={<Announcements />} />
				</Routes>
			</BrowserRouter>
	);
}

export default App;
