export const User = {
	access_token: "",
	scope: "",
	corporateHealthFacility: {
		name: "",
		id: "",
	},
	token_type: "",
	expires_in: 0,
	user: {
		firstName: "",
		lastName: "",
		role: "",
		isVerified: false,
		avatarUrl: "" || null,
		patient: {
			patientId: "",
			dateOfBirth: "",
			id: 0,
			user: {
				id: 0,
				username: "",
			},
		},
		isEnabled: false,
		otherName: "" || null,
		id: 0,
		healthServiceConsultant: {},
		username: "",
	},
};

export interface Investigations {
	labRequestId: string;
	labTestId: string;
	labTestName: string;
	requestDate: "2023-03-10T12:02:30.350Z";
	requestStatusName: string;
	specialistId: string;
}

export interface Prescription {
	prescriptionDate: string;
	prescriptionId: string;
	prescriptionName: string;
	quantity: number;
	specialistId: string;
	status: string;
	unitCost: number;
	visitationId: string;
}

export interface Appointments {
	appointmentDate: string;
	appointmentId: string;
	appointmentName: string;
	gender: string;
	patientAge: number;
	patientName: string;
	patientId: string;
	status: string;
	type: string;
}

export type Inputs = {
	firstName: string;
	otherName: string;
	lastName: string;
	dateOfBirth: string;
	gender: string;
	username: number;
	streetAddress: string;
	digitalAddress: string;
	regionOrState: string;
	city: string;
	country: string;
	email: string;
};