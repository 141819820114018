import axios from "axios";

const SPARK_BASE_URL =
	process.env.REACT_APP_ENV === "production"
		? process.env.REACT_APP_PROD_BASE_URL
		: process.env.REACT_APP_SANDBOX_BASE_URL;
const instance = axios.create({ baseURL: SPARK_BASE_URL });

const client_secret = process.env.REACT_APP_CLIENT_SECRET;
const client_code = process.env.REACT_APP_CLIENT_CODE;

export const UserAPI = {
	gettoken: async () => {
		return instance.post(
			`/users/oauth2/token?grant_type=apiIntegration&client_id=tth-api-client&client_secret=${client_secret}&username=&password=&code=${client_code}`
		);
	},
	signup: async (data, token_type, access_token) => {
		return instance.post(`/users/v1/sign/up`, data, {
			headers: {
				"Content-Type": "application/json",
				Authorization: `${token_type} ${access_token}`,
			},
		});
	},
	signin: async (data) => {
		return instance.post(
			`/users/oauth2/token?grant_type=password&client_id=tth-api-client&client_secret=${client_secret}&username=${data.username}&password=${data.password}&code=${client_code}`
		);
	},
};

export const AppointmentsAPI = {
	getAppointments: async (access_token, healthFacilityId, patientId) => {
		return instance.get(
			`/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/appointments?startDate=01 Jan 2018 00:00:00&endDate=01 Jan 2022 23:59:59`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
	},
};

export const InvestigationsAPI = {
	getInvestigations: async (access_token, healthFacilityId, patientId) => {
		return instance.get(
			`/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/investigations`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
	},
	getByID: async (access_token, healthFacilityId, patientId, investigationId) => {
		return instance.get(
			`/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/investigations/${investigationId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
	},
};

export const PrescriptionAPI = {
	getPrescription: async (access_token, healthFacilityId, patientId) => {
		return instance.get(
			`/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/prescriptions`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
	},
	getByID: async (access_token, healthFacilityId, patientId, visitationId) => {
		return instance.get(
			`/ext-services/v1/corporate/health/facility/${healthFacilityId}/patients/${patientId}/prescriptions/${visitationId}`,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${access_token}`,
				},
			}
		);
	},
};
