import { Box, CircularProgress, Modal } from "@mui/material";
import React from "react";
import { BsFillChatFill, BsCheck } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../components/Modal";
import { useReduxDispatch, useReduxSelector } from "../redux/hooks";
import { getUserSuccessful } from "../redux/slice/UserSlice";

const Payments = () => {
	const { isSignedIn } = useReduxSelector((state) => state.user);
	const navigate = useNavigate();
	React.useEffect(() => {
		isSignedIn && window.location.assign("/find-a-doctor");
	}, [isSignedIn, navigate]);
	const [view, setView] = React.useState("initial");
	const [open, setOpen] = React.useState(false);
	const dispatch = useReduxDispatch();

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	return (
		<div>
			{/* initial stage */}
			{view === "initial" && (
				<div className="py-8 px-8 xl:px-16 relative">
					<div>
						<img
							src="/assets/trust-hospital-logo.png"
							alt="Trust hospital logo"
							width={180}
						/>
					</div>
					<div className="mt-8">
						<div>
							<h1 className="text-primary-50 text-xl">
								Kindly Make Payment Before Booking an Appointment
							</h1>
						</div>
						<div className="mt-12 md:w-2/5 xl:w-1/4 text-[#515151] font-medium">
							<div className="flex justify-between">
								<p>Registration Fees</p>
								<p className="text-primary-50 font-bold">GHS 760.00</p>
							</div>
							<div className="flex justify-between">
								<p>Consultation Fees</p>
								<p className="text-primary-50 font-bold">GHS 760.00</p>
							</div>
							<div className="flex justify-between">
								<p>Taxes & Fees (10%)</p>
								<p className="text-primary-50 font-bold">GHS 760.00</p>
							</div>
							<div className="border-b border-gray-700"></div>
							<div className="flex justify-between mt-2">
								<p>Total</p>
								<p className="text-primary-50 font-bold">GHS 760.00</p>
							</div>
						</div>
					</div>

					<div className="mt-12 md:w-2/5 xl:w-1/4 font-medium text-[#515151]">
						<h1 className="font-medium text-lg">Select Payment Method</h1>
						<label
							className="flex mt-5 space-x-8 justify-between"
							htmlFor="payment"
						>
							<div className="flex items-center space-x-3">
								<img src="/assets/mtn_icon.png" alt="Mtn Icon" className="" />
								<p>MTN Wallet</p>
							</div>
							<input type="radio" name="payment" value="mtn" defaultChecked />
						</label>
					</div>
					<div className="flex justify-center w-full lg:justify-end mt-12 lg:mt-10">
						<button
							className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm lg:mr-28 hover:opacity-95"
							onClick={() => setView("enterphonenumber")}
						>
							Continue
						</button>
					</div>

					<div className="absolute lg:top-24 md:top-40 xl:right-40 right-16 hidden md:block">
						<img
							src="/assets/wallet.png"
							alt="wallet"
							className="md:w-[300px] lg:w-[400px]"
						/>
					</div>
				</div>
			)}
			{/* enter phone number */}
			{view === "enterphonenumber" && (
				<div className="py-8 px-8 xl:px-16 relative">
					<div>
						<img
							src="/assets/trust-hospital-logo.png"
							alt="Trust hospital logo"
							width={180}
						/>
					</div>
					<div className="mt-8">
						<div>
							<h1 className="text-primary-50 text-xl">
								Kindly Make Payment Before Booking an Appointment
							</h1>
						</div>
						<div className="mt-12 md:w-2/5 xl:w-1/4 text-[#515151] font-medium">
							<div className="flex justify-center">
								<img src="/assets/mtn-big.png" alt="Mtn Icon" width={300} />
							</div>
						</div>
					</div>

					<div className="mt-12 md:w-2/5 xl:w-1/4 font-medium text-black">
						<input
							name="phone"
							placeholder="Enter Phone Number"
							type="text"
							id="phone"
							className="h-10 px-2 shadow-sm focus:ring-primary-100 focus:border-primary-100 focus:outline-primary-100 block w-full sm:text-sm border border-tertiary-200 bg-white rounded-3xl"
						/>
					</div>
					<div className="flex w-full md:justify-end justify-center mt-12 lg:mt-10">
						<button
							className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm lg:mr-28 hover:opacity-95"
							onClick={() => setView("authorize")}
						>
							Continue
						</button>
					</div>

					<div className="absolute lg:top-24 md:top-40 xl:right-40 right-16 hidden md:block">
						<img
							src="/assets/wallet.png"
							alt="wallet"
							className="md:w-[300px] lg:w-[400px]"
						/>
					</div>
				</div>
			)}
			{/* authorize */}
			{view === "authorize" && (
				<div className="flex flex-col justify-center items-center py-8 px-12 font-medium">
					<img
						src="/assets/trust-hospital-logo.png"
						alt="Trust hospital logo"
						className="w-[150px]"
					/>
					<h1 className="text-primary-50 text-xl my-10 text-center">
						Kindly Make Payment Before Booking an Appointment
					</h1>
					<div className="space-y-5">
						<p className="text-center">
							Please follow the instructions and do not refresh
						</p>
						<p className="text-center">or</p>
						<p className="text-center">leave this page.</p>
						<p className="text-center">This may take up to 2 minutes.</p>
					</div>
					<h3 className="text-center md:w-3/5 my-5 text-primary-50">
						You will receive a prompt on your mobile number to enter your PIN to
						authorize your payment request
					</h3>
					<CircularProgress />
					<button
						className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm hover:opacity-95 mt-10"
						onClick={handleOpen}
					>
						Continue
					</button>
				</div>
			)}
			<ModalComponent open={open} handleClose={handleClose}>
				<div className="bg-[#85D007] flex rounded-3xl text-white py-5 px-2 md:px-4 justify-around items-start">
					<div className="relative">
						<BsFillChatFill className="text-[#4D860F] md:text-5xl text-2xl" />
						<BsCheck className="absolute md:top-3 top-[5px] left-[5px] md:left-3 md:text-2xl text-sm font-bold" />
					</div>
					<div>
						<h3 className="text-xl md:text-2xl">Well done!</h3>
						<p className="text-xs">Your payment is successfull</p>
					</div>
					<div>
						<MdOutlineClose
							onClick={handleClose}
							className="text-xl cursor-pointer hover:rotate-12"
						/>
					</div>
				</div>

				<div className="w-full flex mt-6 justify-center items-center">
					<button
						className="bg-primary-50 text-white px-20 py-3 rounded-3xl text-sm hover:opacity-95 w-4/6 flex justify-center"
						onClick={() => {
							navigate("/find-a-doctor");
							dispatch(getUserSuccessful("user logged in"));
						}}
					>
						Continue
					</button>
				</div>
				<div className="w-full flex mt-4 justify-center">
					<button
						className="bg-secondary-200 text-primary-50 px-20 py-3 rounded-3xl text-sm hover:bg-blue-100 w-4/6 flex justify-center"
						onClick={handleClose}
					>
						Cancel
					</button>
				</div>
			</ModalComponent>
		</div>
	);
};

export default Payments;
